body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;

  background: -webkit-linear-gradient(220deg, #26A5CA, #F7BC81);

  background: linear-gradient(-130deg, #26A5CA, #F7BC81);
  color: white;
  height: 100vh;
  width: 100vw;
  font-family: 'Lato', sans-serif;
}

h1 {
  font-size: 2.75em;
  margin: 0;
}

h3 {
  font-size: 0.875em;
  font-weight: 100;
  margin: 0;
}

#root {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.header {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 2rem;
  -webkit-animation: fadein 3s;
          animation: fadein 3s;
}

.progress {
  display: -webkit-flex;
  display: flex;
  height: 500px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-transition: height 3000ms 3000ms, opacity 2000ms 4000ms;
  transition: height 3000ms 3000ms, opacity 2000ms 4000ms;
  opacity: 1;
}

.fade-out {
  opacity: 0;
  -webkit-animation: fadeout 2s;
          animation: fadeout 2s;
}

.breath {
  -webkit-animation: breath linear 2.2s infinite;
          animation: breath linear 2.2s infinite;
}

.progress.loading {
  height: 0;
  opacity: 0;
  -webkit-transition: height 3000ms 3000ms, opacity 2000ms 4000ms;
  transition: height 3000ms 3000ms, opacity 2000ms 4000ms;
}

/*
  Mobile
*/

@media only screen and (max-width: 740px) {
  h1 {
    font-size: 1.39em;
  }
  h3 {
    font-size: 0.75em
  }
  .progress {
    height: 250px;
  }
}

/*
  Animantions
*/

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-webkit-keyframes breath{
  0% {
    opacity:0;
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes breath{
  0% {
    opacity:0;
  }
  50% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

/* Chrome web app */
.addToChromeButton {
  text-align: center;
  top: 0;
  right: 0;
  position: absolute;
  font-size: 14px;
  padding: 0.5rem 0.85rem;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  border-radius: 0.5rem;
  margin: 1rem;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.fa-chrome {
  padding-right: 5px;
}

.addToChromeButton:hover {
  border-color: #d6b890;
  color: #d6b890;
}

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

 .CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  width: 100%;
  padding-bottom: 2rem;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #fff;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #fff;
  opacity: 0.2;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #fff;
  font-size: 18px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #fff;
}

